import React, { useState } from "react";
import MobileImage from "../assets/images/Login/dizzy-payment-card-with-coins.gif";
import Coin3 from "../assets/images/Login/coin-3.png";
import Coin5 from "../assets/images/Login/coin-5.png";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../Api/AuthUser";

export const Login = () => {
  
  const {http, setToken} = AuthUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const submitForm = async () => {
      
  
    try {
      const response = await http.post('/admin/signIn', {email:email, password:password}).then((res) => {
          // console.log(res.data.data)
          setToken(res.data.data.name, res.data.data.accessToken)
        })
       
    } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          toast.error(error.response.data.message || 'Login failed. Please try again.');
      } else if (error.request) {
          // Request was made but no response was received
          toast.error('No response from server. Please try again later.');
      } else {
          // Other errors
          toast.error('An error occurred. Please try again.');
      }
      // console.error('Login error:', error);
    }
}


  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="login__main-container">
                <div className="login__heding-box">
                  <h1>Login</h1>
                  <h2>Hi, Welcome Back!</h2>
                  <p>Login To Your Account To Enjoy.</p>
                </div>
                <div className="login__form-container">
                  <div className="login__form">
                    <label htmlFor="Email">Email</label>
                    <input
                      type="email"
                      placeholder="youremail@gmail.com"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <label htmlFor="Password">Password</label>
                    <input
                      type={passwordVisible ? 'text' : 'password'}
                      placeholder="********"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="password-input"
                    />
                    <button
                      type="button"
                      className="toggle-button"
                      onClick={togglePasswordVisibility}
                    >
                       {passwordVisible ? <BsEyeSlash/> : <BsEye/>}
                    </button>

                    {/* <p className="form__container-forgot-password">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </p> */}

                    {/* {error && <p className="error-message">{error}</p>} */}
                    <button type="submit" onClick={submitForm} className="login__form-button">
                      Log In
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login__form-image">
                <img src={MobileImage} alt="Mobile " width="80%" />
              </div>
            </div>
          </div>

          <div className="login-page-coin__wrapper">
            <img src={Coin3} alt="Coin" className="" />
          </div>

          <div className="login-page-coin__wrapper-2">
            <img src={Coin5} alt="" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};
